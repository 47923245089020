export class RutTools {
	static formatter(rut: string, dot = '.') {
		if (rut?.length === 0) return null
	
		// Realizar una Prelimpieza del contenido del Rut, eliminando caracteres no permitidos.
		const parts = []
		let formatted = '', dv = ''
	
		// Eliminar todos los caracteres no validos.
		rut = rut?.replace(/[^0-9Kk]/g, '')
	
		if (rut) {
			// Separar el ultimo caracter como DV.
			dv = rut[rut.length-1]
			rut = rut.substring(0, rut.length-1)
	
			// Dividir en 'partes' la porción del Rut.
			while (rut.length > 0) {
				const section = rut.substring(rut.length-3)
				if (section !== undefined) {
					parts.push(section)
					rut = rut.replace(new RegExp(`${ section }$`), '')
				}
				else {
					parts.push(rut)
					rut = ''
				}
			}
	
			// Finalizar con el formato correspondiente
			for (let i = parts.length; i > 0; i--) formatted += `${ parts[i-1] }${ dot }`
			formatted = formatted.replace(/\.$/, '')
			formatted += `-${ dv }`
			return formatted.replace(/^\-/, '')
		}
	
		return ''
	}

	static validator($rut: string) {
		if (!$rut) return false
		$rut = $rut.replace(/\./g, '')	
		if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test($rut)) return false
	
		var tmp  = $rut.split('-')
		var digv = tmp[1] 
		var rut: unknown = tmp[0]
		if ( digv == 'K' ) digv = 'k'
	
		function dv(T: number) {
			var M = 0, S = 1
			for(; T; T = Math.floor(T / 10)) {
				S = (S + T % 10 * (9 - M++ % 6)) % 11
			}
			return S ? S - 1 : 'k'
		}
	
		return dv(<number>rut) == digv
	}
}